import {
  Routes,
  Route
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import '../App.css';
import { ROUTES } from '../contants/routes';
import { loadData } from "../utils/localStorage";

import Login from '../components/auth/login/login';
import ResetPassword from "../components/auth/resetPassword/resetPassword";
import ForgotPassword from '../components/auth/forgotPassword/forgotPassword';

import SalesDashboard from '../components/dashboard/salesDashboard';


function App() {
  const accessToken = loadData("access")

  return (
    <Routes>
      <Route exact path={ROUTES.LOGIN} element={<Login />}></Route>
      <Route exact path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />}></Route>
      <Route exact path={ROUTES.RESET_PASSWORD + "/:otp/:email"} element={<ResetPassword />}></Route>

      {
        accessToken != null
        &&
        <>
          <Route exact path={ROUTES.DASHBOARD} element={<SalesDashboard />}></Route>
          
          
        </>
      }

      <Route path="*" element={<Login />} />
    </Routes>
  );
}

export default App;
