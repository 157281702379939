import React from "react";

import "./salesDashboard.css";
import SideBar from "../../commonComponents/sidebar/sidebar";
import { sidebarNavItems, COMMING_SOON_IMAGE } from "../../contants";
import TopNavigation from "../../commonComponents/topNavigation/topNavigation";


const MentorDashbaord = () => { 
    return (
        <div className='mentor-dashboard-container' style={{ display: 'flex' }}>
            <SideBar activeItem={sidebarNavItems.dashboard} />
            <div className='mentor-dashboard-side-container'>
                <TopNavigation activeItem={sidebarNavItems.dashboard} />
                
                <div className='row mentor-dashboard-filter-container'>
                    
                </div>
                
                <div className='mentor-dashboard-comming-soon'>
                    <h1 className='mentor-dashboard-comming-soon-text'>Coming Soon</h1>
                    <img
                        src={COMMING_SOON_IMAGE}
                        className='mentor-dashboard-comming-soon-img'
                        alt='coming soon'
                    />
                </div>
            </div>
        </div>
    );
}


export default MentorDashbaord;
