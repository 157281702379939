import { toast } from 'react-toastify';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import z from "zod";
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from "react-hook-form";

import "./forgotPassword.css";
import { ROUTES, MESSAGE } from '../../../contants';
import BaseNavbar from '../../../commonComponents/navbar/baseNavbar';
import { forgotPasswordService } from "../../../modules/services";
import InputField from '../../../commonComponents/inputField/inputField';
import PrimaryButton from '../../../commonComponents/primaryButton/primaryButton';


const ForgotPassword = () => {
    
    const [isLoading, setIsLoading] = useState(false);
    
    const loginSchema = z.object({
        email: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Email")}).email({message: MESSAGE.EMAIL_INVALID}),
        role: z.string().default("REFERRALS")
    });
    const { register, handleSubmit, setError, reset, formState: { errors } } = useForm({
        resolver: zodResolver(loginSchema)
    });


    const handleForgotPassword = (data) => {
        setIsLoading(true);

        forgotPasswordService(data, {}).then(apiResponse => {
            toast.success(apiResponse.data.message[0], {
                position: toast.POSITION.TOP_RIGHT,
            });
            setIsLoading(false);
            reset();

            // window.location.pathname = ROUTES.DASHBOARD;
        }).catch(error => { 
            setIsLoading(false);
            if (error.response.data.error === "email") {
                setError("email", {type: "custom", message: error.response.data.message[0]})
            }
            else {
                toast.error(error.response.data.message[0], {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
    }

    return (
        <>
            <BaseNavbar />
            <div className='forgot-main-div'>
                <div className='forgot-form-div'>
                    <Form onSubmit={handleSubmit(handleForgotPassword)}>
                        <Form.Label className='forgot-heading'>
                            Forgot Password
                        </Form.Label>
                        <Form.Label className='forgot-sub-heading'>
                            hope you will find your credentials!
                        </Form.Label>

                        <InputField
                            iconClass="bi-envelope-fill"
                            placeholder="Email"
                            fieldType="email"
                            errorMessage={errors.email?.message}
                            inputKey="email"
                            formRegister={register}
                        />
                        <br />

                        <div className='forgot-submit-button-div'>
                            <PrimaryButton
                                text="SUBMIT"
                                type={"submit"}
                                isLoading={isLoading}
                                customStyle={{height: "40px"}}
                            />
                            <br />
                            <br />
                            <Link to={ROUTES.LOGIN} className='forgot-back-to-login'>
                                <u>Back to Login</u>
                            </Link>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;
