import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import "./baseNavbar.css";
import { MAIN_LOGO } from "../../contants";

function BaseNavbar(props) {
  return (
    <Navbar collapseOnSelect expand="lg" bg="white" variant="white" className="main-class">
      <Container>
        <Navbar.Brand href="https://fizrobotics.com/">
        <img
              src={MAIN_LOGO}
              width="193.6px"
              height="70.4px"
              className="d-inline-block align-top navbar-logo"
              alt="FRS logo"
            />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          </Nav>
          <Nav>
            <Nav.Link href="https://fizrobotics.com/" className="navbar-navs-text">
              HOME
            </Nav.Link>
            <Nav.Link eventKey={2} href="https://fizrobotics.com/school/" className="navbar-navs-text">
              COURSES
            </Nav.Link>
            <Nav.Link eventKey={2} href="https://fizrobotics.com/fire-robotics-lab/" className="navbar-navs-text">
              LABS
            </Nav.Link>
            <Nav.Link eventKey={2} href="https://fizrobotics.com/workshops/" className="navbar-navs-text">
              WORKSHOP
            </Nav.Link>
            <Nav.Link eventKey={2} href="#" className="navbar-navs-text navbar-navs-text-active">
              SIGN IN
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    );
}

export default BaseNavbar;
