export const sidebarNavItems = {
    dashboard: "Dashboard",
}

export const genderList = [
    {
        label: "Male",
        value: "MALE"
    },
    {
        label: "Female",
        value: "FEMALE"
    },
    {
        label: "Others",
        value: "OTHERS"
    },
]

export const genderSimpleList = [
    "MALE", "FEMALE", "Others"
]

export const ERROR = "error";
export const SERVER_ERROR = "Server Error";
