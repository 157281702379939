export const saveJsonData = (key, jsonData) => {
  try {
    const serializedState = JSON.stringify(jsonData);
    sessionStorage.setItem(key, serializedState);
  } catch (err) { 
    console.log(err);
  }
}

export const saveData = (key, data) => { 
  try {
    sessionStorage.setItem(key, data);
  } catch (err) { 
    console.log(err);
  }
}

export const loadData = (key) => { 
  try { 
    if (sessionStorage.getItem(key) === null) {
      return null;
    }
    return sessionStorage.getItem(key);
  } catch (err) {
    console.log(err);
  }
}

export const clearData = () => {
  try {
    sessionStorage.clear();
  } catch (err) {
    console.log(err);
  }
}
