import axios from 'axios';
import { toast } from 'react-toastify';

import * as url from "../../utils/urls";
// import axiosInstance from '../../utils/axios';
// import { loadData } from '../../utils/localStorage';
import { ERROR, SERVER_ERROR } from "../../contants/constants";


// const historyId = JSON.parse(loadData("user"))?.history_id;


export const loginService = async (data, queryParams) => {

  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return axios.post(`${url.getUserLoggedInUrl}?${queryParamsList}`, data)
  }
  catch (error) {
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
};

export const forgotPasswordService = async (data, queryParams) => {
  
  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return axios.post(`${url.getForgotPasswordUrl}?${queryParamsList}`, data)
  }
  catch (error) {
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
};

export const resetPasswordService = (data) => {

  return axios.patch(
    `${url.getResetPasswordUrl}`,
    data
  )
};
