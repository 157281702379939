import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import App from "./App";


function Root() {
  return (
    <BrowserRouter>
      <DndProvider backend={HTML5Backend}>
        <ToastContainer />
        <App />
      </DndProvider>
    </BrowserRouter>
  );
}

export default Root;
