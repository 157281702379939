import React, { useState } from "react";

import "./inputField.css";


const InputField = ({ iconClass, iconSize, placeholder, fieldType, onChange, errorMessage, inputKey, maxWidth, onFocus, onBlur, onKeyUp, formRegister }) => { 
    
    const [isPassword, setIsPassword] = useState(true);

    const handlePasswordVisible = () => { 
        setIsPassword(!isPassword);
    }

    return (
        <div style={maxWidth && {
            maxWidth: maxWidth
        }}>
            <div className={
                errorMessage ?
                    'input-field-control input-field-invalid'
                    :
                    "input-field-control"
            }>
                <i className={"bi " + iconClass + " input-icon"} style={iconSize && {fontSize: iconSize}}></i>
                <input
                    className='input-field input-field-text'
                    type={
                        isPassword ?
                            fieldType
                            :
                            "text"
                    }
                    id={inputKey}
                    name={inputKey}
                    placeholder={placeholder}
                    onChange={(e) => onChange(e.target.value)}
                    onFocus={(e) => onFocus && onFocus(e)}
                    onBlur={(e) => onBlur && onBlur(e)}
                    onMouseOver={(e) => onFocus && onFocus(e)}
                    onKeyUp={onKeyUp}
                    {...formRegister(inputKey)}
                />
                {
                    fieldType === "password" &&
                    <i className={
                            isPassword ?
                                "bi bi-eye-fill input-icon"
                                :
                                "bi bi-eye-slash-fill input-icon"
                        } onClick={handlePasswordVisible}></i>
                }
            </div>
            <span className="input-field-invalid-text">{errorMessage}</span>
        </div>
    );
} 


export default InputField;
