import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';

import { ROUTES } from "../../../contants";
import { clearData } from "../../../utils/localStorage";
import PrimaryButton from "../../../commonComponents/primaryButton/primaryButton";


const LogoutModal = ({ logoutModal, handleCloseLogoutModal }) => {
    const [isLoading, setIsLoading] = useState(false);
    
    const handleSubmit = () => {
        setIsLoading(true);
        clearData();
        window.location.pathname = ROUTES.LOGIN;
    }


    return (
        <Modal show={logoutModal} onHide={handleCloseLogoutModal} size="md" aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header closeButton>
                <Modal.Title>Logout</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>Are you sure want to logout?</h6>
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton text="Close" onClick={handleCloseLogoutModal} buttonColor="grey" isLoading={isLoading} />
                <PrimaryButton text="Logout" onClick={handleSubmit} isLoading={isLoading} />
            </Modal.Footer>
        </Modal>
    );
}

export default LogoutModal;
