import { toast } from 'react-toastify';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { decode as base64_decode } from 'base-64';
import { z } from "zod";
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from "react-hook-form";

import "./resetPassword.css";
import { ROUTES, MESSAGE } from '../../../contants';
import BaseNavbar from '../../../commonComponents/navbar/baseNavbar';
import { resetPasswordService } from "../../../modules/services";
import InputField from '../../../commonComponents/inputField/inputField';
import PrimaryButton from '../../../commonComponents/primaryButton/primaryButton';


const ResetPassword = (props) => {
    
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    let { otp, email } = useParams();

    const loginSchema = z.object({
        password: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Password")}),
        confirm_password: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Confirm Password")}),
        email: z.string().default(base64_decode(email)),
        otp: z.string().default(otp)
    }).refine((data) => data.password === data.confirm_password, {
        message: MESSAGE.CONFIRM_PASSWORD_INVALID,
        path: ["confirm_password"]
    });
    const { register, handleSubmit, setError, reset, formState: { errors } } = useForm({
        resolver: zodResolver(loginSchema)
    });

    const handleResetPassword = (data) => {

        resetPasswordService(data, {}).then(apiResponse => {
            toast.success(apiResponse.data.message[0], {
                position: toast.POSITION.TOP_RIGHT,
            });
            setIsLoading(false);
            navigate(ROUTES.LOGIN);

        }).catch(error => { 
            setIsLoading(false);
            if (error.response.data.error === "password" || error.response.data.error === "confirm_password") {
                setError(error.response.data.error, {type: "custom", message: error.response.data.message[0]})
            }
            else {
                toast.error(error.response.data.message[0], {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
    }


    return (
        <>
            <BaseNavbar />
            <div className='forgot-main-div'>
                <div className='forgot-form-div'>
                    <Form noValidate onSubmit={handleSubmit(handleResetPassword)}>
                        <Form.Label className='forgot-heading'>
                            RESET PASSWORD
                        </Form.Label>
                        <Form.Label className='forgot-sub-heading'>
                            Resume your FRL journey!
                        </Form.Label>

                        <InputField
                            iconClass="bi-shield-lock-fill"
                            placeholder="New Password"
                            fieldType="password"
                            errorMessage={errors.password?.message}
                            inputKey="password"
                            formRegister={register}
                        />

                        <InputField
                            iconClass="bi-shield-lock-fill"
                            placeholder="Confirm Password"
                            fieldType="password"
                            errorMessage={errors.confirm_password?.message}
                            inputKey="confirm_password"
                            formRegister={register}
                        />
                        <br />

                        <div className='forgot-submit-button-div'>
                            <PrimaryButton
                                text="SUBMIT"
                                type={"submit"}
                                isLoading={isLoading}
                                customStyle={{height: "40px"}}
                            />
                            <br />
                            <br />
                            <Link to={ROUTES.LOGIN} className='forgot-back-to-login'>
                                <u>Back to Login</u>
                            </Link>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default ResetPassword;
