import { toast } from 'react-toastify';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import "./login.css";
import { ROUTES, MESSAGE } from '../../../contants';
import BaseNavbar from '../../../commonComponents/navbar/baseNavbar';
import { loginService } from "../../../modules/services";
import { saveJsonData, saveData } from "../../../utils/localStorage";
import InputField from '../../../commonComponents/inputField/inputField';
import PrimaryButton from '../../../commonComponents/primaryButton/primaryButton';


const Login = () => {
    const [isLoading, setIsLoading] = useState(false);

    const loginSchema = z.object({
        username: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Email")}).email({message: MESSAGE.EMAIL_INVALID}),
        password: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Password") }),
        role: z.string().default("REFERRALS")
    });
    const { register, handleSubmit, setError, formState: { errors } } = useForm({
        resolver: zodResolver(loginSchema)
    });

    const handleSignIn = (data) => {

        setIsLoading(true);

        loginService(data, {}).then(apiResponse => {
            toast.success(apiResponse.data.message[0], {
                position: toast.POSITION.TOP_RIGHT,
            });
            saveJsonData("user", apiResponse.data.data);
            saveData("access", apiResponse.data.data.token.access);
            saveData("refresh", apiResponse.data.data.token.refresh);
            setIsLoading(false);

            window.location.pathname = ROUTES.DASHBOARD;
        }).catch(error => { 
            setIsLoading(false);
            if (error.response.data.error === "username" || error.response.data.error === "password") {
                setError(error.response.data.error, {type: "custom", message: error.response.data.message[0]})
            }
            else {
                toast.error(error.response.data.message[0], {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
    }

    return (
        <>
            <BaseNavbar />
            <div className='main-div'>
                <div className='form-div'>
                    <Form onSubmit={handleSubmit(handleSignIn)}>
                        <Form.Label className='heading'>
                            SIGN IN
                        </Form.Label>
                        <Form.Label className='sub-heading'>
                            Welcome, to FIZ Robotic Solutions
                            <br/>Let the FIRE begin!
                        </Form.Label>

                        <InputField
                            iconClass="bi-envelope-fill"
                            placeholder="Email"
                            fieldType="text"
                            errorMessage={errors.username?.message}
                            inputKey="username"
                            formRegister={register}
                        />
                        <InputField
                            iconClass="bi-shield-lock-fill"
                            placeholder="Password"
                            fieldType="password"
                            errorMessage={errors.password?.message}
                            inputKey="password"
                            formRegister={register}
                        />
                        <div className='login-forgot-div'>
                        <Link to={ROUTES.FORGOT_PASSWORD} className='login-forgot-text'>
                            <u>Forgot Password?</u>
                        </Link>
                        </div>
                        <br />

                        <div className='submit-button-div'>
                            <PrimaryButton
                                text="SIGN IN"
                                isLoading={isLoading}
                                type={"submit"}
                                customStyle={{height: "40px"}}
                            />
                        </div>
                        <div className='create-account-div'>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default Login;
