import React from "react";
import { Spinner } from "react-bootstrap";
import "./primaryButton.css";


const PrimaryButton = ({ text, isLoading, onClick, customStyle, onlyDisabled = null, buttonColor = "primary", textSmallScreen=null, type=null }) => {
    
    var disabled;
    if (onlyDisabled !== null) {
        
        disabled = onlyDisabled;
    }
    else {
        disabled = isLoading;
    }
    const buttonColorCodes = {
        primary: { backgroundColor: "#293659" },
        grey: {backgroundColor: "#707683"}
    }
    
    return (
        <button
            className={textSmallScreen ? "primary-button primary-button-small-screen" : "primary-button primary-button-full-screen"}
            disabled={disabled}
            onClick={onClick}
            type={type}
            style={Object.assign({}, buttonColorCodes[buttonColor], { ...customStyle })}
        >
            {
                isLoading ?
                <Spinner animation="border" className="primary-button-loader" />
                    :
                    <>
                        {
                            textSmallScreen ?
                                <>
                                    <span className="primary-button-text">{text}</span>
                                    <span className="primary-button-text-small-screen">{textSmallScreen}</span>
                                </>
                                :
                                <span>{text}</span>
                        }
                        
                    </>
            }
        </button>
    );
}

export default PrimaryButton;
